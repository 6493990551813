import { create } from "zustand";
import { httpService } from "../../../base/services/httpService.service";
import {
   debounce,
   addQuestion,
   deleteQuestion,
   addInnerSection,
   deleteSection,
   addSection,
   addFirstSection,
   duplicateQuestion,
   updateQuestionHint,
   updateQuestionLabel,
   updateQuestionType,
   updateQuestionChoices,
   updateSecondQuestionChoices,
   updateRangeValues,
   updateIsRequiredStatus,
   updateMaxChoices
} from "./helpers";
import { Constants } from "../../../utils/constants";
import { loadDraft } from "../../../storage/db";
import { InputType } from "../../../utils/enums";

export const useSurveyStore = create((set) => ({
   isLoading: false,
   isSurveyLoading: false,
   surveyAlldata: [],
   surveyData: [],
   surveyBaseLanguage: "ecfb845a-86f0-476c-a4df-f101d35a96f9",
   arabicLanguageID: Constants.ARABIC_LANGUAGE_ID,
   surveyBaseLanguageName: "",

   fetchSurveyById: (payload, loading = true) => {
      set({ isSurveyLoading: loading });

      httpService.post(
         "Survey/GetById",
         {
            SurveyId: payload.surveyId,
            languageId: payload.languageId
         },
         (res) => {
            const language =
               res?.data.surveyLanguageMaps?.find((lang) => lang.isBaseLanguage) || null;
            set({
               surveyAlldata: res?.data,
               surveyData: res?.data?.surveyItems,
               isSurveyLoading: false,
               surveyBaseLanguage: language.languageId, // You can use the correct value here
               surveyBaseLanguageName: language.name
            });
         }
      );
   },
   fetchSurveyByyId: (payload, loading = true) => {
      set({ isSurveyLoading: loading });

      httpService.get(
         `/Survey/GetPreviewData?surveyId=${payload.surveyId}&languageId=${payload.languageId}`,
         // {
         //    SurveyId: payload.surveyId,
         //    languageId: payload.languageId
         // },
         (res) => {
            const language =
               res?.data.surveyLanguageMaps?.find((lang) => lang.isBaseLanguage) || null;
            set({
               surveyAlldata: res?.data,
               surveyData: res?.data?.surveyItems,
               isSurveyLoading: false,
               surveyBaseLanguage: language.languageId, // You can use the correct value here
               surveyBaseLanguageName: language.name
            });
         }
      );
   },
   setSurveySubmissionData: (key, value) => {
      set((state) => ({
         surveySubmissionData: {
            ...state.surveySubmissionData,
            [key]: value
         }
      }));
   },

   fetchSurveyDetailsById: (surveyId, loading = true) => {
      set({ isSurveyLoading: loading });

      httpService.get(`Survey/GetSurveyDetails?surveyId=${surveyId}`, (res) => {
         const surveyAllData = {
            ...res.data,
            languageId: res.data?.surveyLanguageMaps?.find((language) => language.isBaseLanguage)
               ?.languageId
         };
         set({
            surveyAlldata: surveyAllData,
            isSurveyLoading: false
         });
      });
   }
}));

export const useSurveyInputStore = create((set) => ({
   inputs: {},
   getErrors: (renderedElementIds, inputs) => {
      const surveyQuestions = editDataStore.getState().surveyQuestions;

      const errorKeys = [];

      surveyQuestions.forEach((question) => {
         if (question.isRequired) {
            if (!inputs[question.id]) {
               errorKeys.push(question.id);
            } else {
               const answerDetailKey = Object.keys(inputs[question.id].answerDetail)[0];
               const answerDetail = inputs[question.id].answerDetail[answerDetailKey];
               if (
                  answerDetail &&
                  (answerDetail === "" ||
                     answerDetail.length === 0 ||
                     (typeof answerDetail === "string" && answerDetail.trim() === ""))
               ) {
                  errorKeys.push(question.id);
               }
            }
         }
      });

      const relevantErrors = errorKeys.filter((key) => renderedElementIds.includes(key));

      return relevantErrors;
   },
   setInputs: (e) => {
      // type e = {
      //    name: string,
      //    value: {
      //        surveyId: string,
      //        userId: string,
      //        questionId: string,
      //        questionType: 40,
      //        name: string
      //        skipLogicAnswer: any,
      //        answerDetail: {
      //            [key:string]: any
      //        }
      //    }
      // }
      if (e) {
         set((state) => {
            // name id the question id - uuid
            const { name, value } = e?.target;
            const updatedInputs = { ...state.inputs };

            if (value !== "") {
               updatedInputs[name] = value;
            } else {
               delete updatedInputs[name];
            }

            return { inputs: updatedInputs };
         });
      } else {
         set(() => {
            return { inputs: {} };
         });
      }
   },
   setDraftSurvey: async (surveyId, draftId) => {
      //TODO: try catch
      const draftData = await loadDraft(surveyId, draftId);
      set({ inputs: draftData.formData });
   },
   resetInputs: () => {
      set(({ inputs }) => {
         const surveyQuestions = editDataStore.getState().surveyQuestions;

         const updatedInputs = { ...inputs };

         surveyQuestions.forEach((question) => {
            if (Object.keys(updatedInputs).includes(question.id) && question.default === null) {
               delete updatedInputs[question.id];
            }
         });

         return { inputs: updatedInputs };
      });
   }
}));

export const editDataStore = create((set) => ({
   editData: {
      deletedSections: [],
      deletedQuestions: []
   },
   isSurveyLoading: false,
   surveyData: [],
   surveyQuestions: [],
   surveyAllData: {},
   surveyDataResponse: {},
   toggleLocation: false,

   resetEditData: () => {
      set({
         editData: {
            deletedSections: [],
            deletedQuestions: []
         }
      });
   },
   handleDnDChange: (result) => {
      const { destination, source } = result;

      // If dropped outside a valid destination
      if (!destination) return;

      // If dropped in the same position
      if (source.droppableId === destination.droppableId && source.index === destination.index) {
         return;
      }

      // Helper to find and remove the item from the source
      const findAndRemoveItem = (items, droppableId, index) => {
         for (const item of items) {
            if (item.id === droppableId) {
               // Found the target section or question
               return [item.innerItems.splice(index, 1)[0], items];
            }
            if (item.innerItems) {
               const [foundItem] = findAndRemoveItem(item.innerItems, droppableId, index);
               if (foundItem) return [foundItem, items];
            }
         }
         return [null, items];
      };

      // Helper to insert the item into the destination
      const insertItem = (items, droppableId, index, itemToInsert) => {
         for (const item of items) {
            if (item.id === droppableId) {
               // Found the target section or question
               item.innerItems.splice(index, 0, itemToInsert);
               return items;
            }
            if (item.innerItems) {
               const updatedInnerItems = insertItem(
                  item.innerItems,
                  droppableId,
                  index,
                  itemToInsert
               );
               if (updatedInnerItems?.length) {
                  return items;
               }
            }
         }
         return items;
      };

      // Clone the sections to avoid mutating state directly
      const updatedSections = structuredClone(editDataStore.getState().surveyData);

      // Remove the item from the source
      const [movedItem, sectionsWithoutSource] = findAndRemoveItem(
         updatedSections,
         source.droppableId,
         source.index
      );

      if (!movedItem) return; // If no item was found, abort

      // Insert the item into the destination
      const sectionsWithInsertedItem = insertItem(
         sectionsWithoutSource,
         destination.droppableId,
         destination.index,
         movedItem
      );

      const updateOrder = (items) => {
         for (const [index, item] of items.entries()) {
            item.surveyItem.order = index + 1;
            if (item.innerItems) {
               updateOrder(item.innerItems);
            }
         }
      };
      updateOrder(sectionsWithInsertedItem);

      set({ surveyData: sectionsWithInsertedItem });
   },

   changeSurveyStatus: (newStatus) => {
      set((state) => ({
         surveyAllData: {
            ...state.surveyAllData,
            surveyStatusId: newStatus
         }
      }));
   },
   fetchSurveyById: (payload, loading = true) => {
      set({ isSurveyLoading: loading });

      const ITEM_LABEL = {
         SECTION: "Section",
         QUESTION: "Question"
      };
      const getQuestionsRecursively = (items) => {
         return items.flatMap((item) => {
            if (item.itemLabel === ITEM_LABEL.SECTION) {
               return getQuestionsRecursively(item.innerItems);
            } else {
               return item.surveyItem;
            }
         });
      };
      const params = new URLSearchParams({
         surveyId: payload.surveyId,
         languageId: payload.languageId,
         ...(payload.taskId && { taskId: payload.taskId }),
         ...(payload.receiverId && { messageReceiverId: payload.receiverId })
      });
      httpService.get(
         `Survey/GetPreviewData?${params.toString()}`,
         (res) => {
            function assignXpathRecursively(items, parentXpath) {
               items.forEach((item) => {
                  item.xpath = `${parentXpath}/${item.surveyItem.name}${
                     item.itemLabel === ITEM_LABEL.SECTION && item.surveyItem.repeat_count
                        ? "__repeat"
                        : ""
                  }`;
                  if (item.innerItems) {
                     assignXpathRecursively(item.innerItems, item.xpath);
                  }
               });
            }

            const surveyData = res?.data?.surveyItems?.map((item) => {
               if (item.itemLabel === ITEM_LABEL.SECTION && item.innerItems) {
                  item.innerItems.forEach((innerItem, innerIndex) => {
                     innerItem.surveyItem.order = innerIndex + 1; // Start order count from 1 for each section
                  });
               }
               return item;
            });

            assignXpathRecursively(surveyData, res?.data?.id);

            const language =
               res?.data.surveyLanguageMaps?.find((lang) => lang.isBaseLanguage) || null;

            const surveyQuestions = getQuestionsRecursively(surveyData);

            set({
               surveyDataResponse: JSON.stringify(res.data),
               isSurveyLoading: false,
               surveyData: surveyData,
               surveyQuestions: surveyQuestions,
               surveyAllData: res?.data,
               surveyBaseLanguage: language.languageId,
               surveyBaseLanguageName: language.name,
               toggleLocation: res.data.toggleLocation
            });
         },
         (_error) => {
            set({ isSurveyLoading: false });
         }
      );
   },
   deleteQuestion: (sectionId, questionId) => {
      const updatedSurveyData = [...editDataStore.getState().surveyData];
      const editData = { ...editDataStore.getState().editData };

      if (questionId === InputType.SECTION) {
         deleteSection(updatedSurveyData, sectionId);
         set({
            surveyData: updatedSurveyData,
            editData: { ...editData, deletedSections: [...editData.deletedSections, sectionId] }
         });
      } else {
         deleteQuestion(updatedSurveyData, sectionId, questionId);
         set({
            surveyData: updatedSurveyData,
            editData: { ...editData, deletedQuestions: [...editData.deletedSections, questionId] }
         });
      }
   },
   addQuestion: (payload) => {
      set((state) => {
         const updatedSurveyData = [...state.surveyData];

         if (payload.questionType === 0) {
            addInnerSection(updatedSurveyData, payload);
         } else {
            addQuestion(updatedSurveyData, payload);
         }
         return { surveyData: updatedSurveyData };
      });
   },
   duplicateElement: (sectionId, questionId) => {
      set((state) => {
         const updatedSurveyData = [...state.surveyData];

         duplicateQuestion(updatedSurveyData, sectionId, questionId);
         return { surveyData: updatedSurveyData };
      });
   },
   addSection: (targetId) => {
      set((state) => {
         const updatedSurveyData = [...state.surveyData];

         if (targetId === 0) {
            addFirstSection(updatedSurveyData);
         } else {
            addSection(updatedSurveyData, targetId);
         }

         return { surveyData: updatedSurveyData };
      });
   },
   changeRequiredStatus: (id, status) => {
      set((state) => {
         const updatedEditData = { ...state.editData };
         if (!updatedEditData[id]) {
            updatedEditData[id] = { isRequired: status };
         } else {
            updatedEditData[id].isRequired = status;
         }
         const updatedSurveyData = updateIsRequiredStatus(state.surveyData, id, status);

         return { editData: updatedEditData, surveyData: updatedSurveyData };
      });
   },
   setQuestionTitle: (sectionId, id, text) => {
      set((state) => {
         const updatedEditData = { ...state.editData };
         if (!updatedEditData[id]) {
            updatedEditData[id] = { title: text };
         } else {
            updatedEditData[id].title = text;
         }
         const updatedSurveyData = updateQuestionLabel(state.surveyData, sectionId, id, text);

         return { editData: updatedEditData, surveyData: updatedSurveyData };
      });
   },
   setQuestionHint: (sectionId, id, text) => {
      set((state) => {
         const updatedEditData = { ...state.editData };

         if (!updatedEditData[id]) {
            updatedEditData[id] = { hint: text };
         } else {
            updatedEditData[id].hint = text;
         }

         const updatedSurveyData = updateQuestionHint(state.surveyData, sectionId, id, text);
         return { editData: updatedEditData, surveyData: updatedSurveyData };
      });
   },
   setQuestionType: (sectionId, id, type) => {
      set((state) => {
         const updatedEditData = { ...state.editData };
         if (!updatedEditData[id]) {
            updatedEditData[id] = { type: type };
         } else {
            updatedEditData[id].type = type;
         }
         const updatedSurveyData = updateQuestionType(state.surveyData, sectionId, id, type);
         return { editData: updatedEditData, surveyData: updatedSurveyData };
      });
   },
   setQuestionChoices: (sectionId, id, choices) => {
      set((state) => {
         const updatedEditData = { ...state.editData };
         if (!updatedEditData[id]) {
            updatedEditData[id] = {
               choices: choices
            };
         } else {
            updatedEditData[id].choices = choices;
         }

         const updatedSurveyData = updateQuestionChoices(state.surveyData, sectionId, id, choices);
         return { editData: updatedEditData, surveyData: updatedSurveyData };
      });
   },
   setMaxChoices: (sectionId, id, maxChoices) => {
      set((state) => {
         const updatedEditData = { ...state.editData };
         if (!updatedEditData[id]) {
            updatedEditData[id] = {
               maxChoices: maxChoices
            };
         } else {
            updatedEditData[id].maxChoices = maxChoices;
         }

         const updatedSurveyData = updateMaxChoices(state.surveyData, sectionId, id, maxChoices);
         return { editData: updatedEditData, surveyData: updatedSurveyData };
      });
   },
   setSecondQuestionChoices: (sectionId, id, choices) => {
      set((state) => {
         const updatedEditData = { ...state.editData };
         if (!updatedEditData[id]) {
            updatedEditData[id] = {
               secondChoices: choices
            };
         } else {
            updatedEditData[id].secondChoices = choices;
         }

         const updatedSurveyData = updateSecondQuestionChoices(
            state.surveyData,
            sectionId,
            id,
            choices
         );
         return { editData: updatedEditData, surveyData: updatedSurveyData };
      });
   },
   setRangeOptions: (sectionId, id, rangeOptions) => {
      set((state) => {
         const updatedEditData = { ...state.editData };

         if (!updatedEditData[id]) {
            updatedEditData[id] = {
               range: rangeOptions
            };
         } else {
            updatedEditData[id].range = rangeOptions;
         }

         const updatedSurveyData = updateRangeValues(state.surveyData, sectionId, id, rangeOptions);
         return { editData: updatedEditData, surveyData: updatedSurveyData };
      });
   }
}));

export const surveyLanguageStore = create((set) => ({
   language: "",
   setLanguage: (value) => set({ language: value })
}));

export const useTranslateStore = create((set) => {
   const initialState = {
      toLanguage: "",
      languageStatus: "",
      labelData: [],
      choiceData: [],
      translationSections: [],
      translationLabels: [],
      translateHints: []
   };

   return {
      ...initialState,
      setToLanguage: (value) => set({ toLanguage: value }),
      setLanguageStatus: (value) => set({ languageStatus: value }),
      handleTextChange: debounce((text, questionId, dataType, description) => {
         set((state) => {
            const existingEntryIndex = state.labelData.findIndex(
               (entry) => entry.questionId === questionId
            );
            let newData;
            if (existingEntryIndex !== -1) {
               const updatedData = [...state.labelData];
               updatedData[existingEntryIndex] = { questionId, text, dataType };
               newData = {};
            } else {
               switch (dataType) {
                  case "Section":
                     const filteredSections = (state.translationSections || []).filter(
                        (entry) => entry.sectionId !== questionId
                     );
                     newData = {
                        ...state,
                        translationSections: [
                           ...filteredSections,
                           { sectionId: questionId, name: text, description: text }
                        ]
                     };
                     break;
                  case "Question":
                     const filteredLabels = (state.translationLabels || []).filter(
                        (entry) => entry.questionId !== questionId
                     );
                     newData = {
                        ...state,
                        translationLabels: [...filteredLabels, { questionId, text }]
                     };
                     break;
                  case "Hints":
                     const filteredHints = (state.translateHints || []).filter(
                        (entry) => entry.questionId !== questionId
                     );
                     newData = {
                        ...state,
                        translateHints: [...filteredHints, { questionId, text }]
                     };
                     break;
                  default:
                     newData = { ...state };
               }

               delete newData.labelData;
            }

            return newData;
         });
      }, 500),
      handleChoiceLabel: debounce((choiceId, value) => {
         set((state) => {
            const existingEntryIndex = state.choiceData.findIndex(
               (entry) => entry.choiceLabels[0].choiceId === choiceId
            );

            if (existingEntryIndex !== -1) {
               const updatedData = [...state.choiceData];
               updatedData[existingEntryIndex].choiceLabels[0].text = value;
               return { choiceData: updatedData };
            } else {
               return {
                  choiceData: [
                     ...state.choiceData,
                     {
                        choiceLabels: [
                           {
                              text: value,
                              choiceId: choiceId
                           }
                        ]
                     }
                  ]
               };
            }
         });
      }, 500),
      initializeTranslation: (inputData) => {
         const initialChoiceData = [];
         const initialTranslationSections = [];
         const initialTranslationLabels = [];

         if (!inputData) {
            set({
               choiceData: [],
               translationSections: [],
               translationLabels: []
            });
            return;
         }

         const initializeItem = (items) => {
            items.forEach((item) => {
               if (item.itemLabel === "Question") {
                  item.surveyItem.questionLabels.forEach((label) => {
                     initialTranslationLabels.push({
                        questionId: label.questionId,
                        text: label.text
                     });
                  });

                  if (item.surveyItem.selectQuestionChoices.length > 0) {
                     item.surveyItem.selectQuestionChoices.forEach((choice) => {
                        choice.choiceLabels.forEach((choiceLabel) => {
                           initialChoiceData.push({
                              choiceLabels: [
                                 {
                                    text: choiceLabel.text.trim(),
                                    choiceId: choiceLabel.choiceId
                                 }
                              ]
                           });
                        });
                     });
                  }
               }

               if (item.itemLabel === "Section") {
                  initialTranslationSections.push({
                     sectionId: item.id,
                     name: item.surveyItem.description,
                     description: item.surveyItem.description
                  });

                  if (item.innerItems) {
                     initializeItem(item.innerItems);
                  }
               }
            });
         };

         initializeItem(inputData.surveyItems);

         set({
            choiceData: initialChoiceData,
            translationSections: initialTranslationSections,
            translationLabels: initialTranslationLabels
         });
      }
   };
});

export const useGetDraftedSurvey = create((set) => ({
   survey: "",
   setSurvey: (value) => set({ survey: value })
}));

export const useSurveySkipLogicStore = create((set) => ({
   skipLogicCreate: [],

   setSkipLogicCreate: (question, logicalOperator, skipLogics) => {
      set((state) => {
         const sectionId = question?.itemLabel === "Section" && question?.id;
         const questionId = !question?.itemLabel && question?.id;

         let itemExists = false;
         const updatedSkipLogic = state.skipLogicCreate.map((item) => {
            if (
               (item.questionId && questionId && item.questionId === questionId) ||
               (item.sectionId && sectionId && item.sectionId === sectionId)
            ) {
               itemExists = true;
               const existingSkipLogic = item.skipLogics.find(
                  (logic) => logic.index === skipLogics[0].index
               );

               if (skipLogics[0].questionType === 33 && skipLogics[0].value) {
                  //later: logicaloperator for selectmany should be put directly from preview
                  // item.logicalOperator = 1;
                  if (existingSkipLogic && existingSkipLogic.value === "") {
                     item.skipLogics = item.skipLogics.map((logic) =>
                        logic.index === skipLogics[0].index ? { ...logic, ...skipLogics[0] } : logic
                     );
                  } else {
                     item.skipLogics.push({ ...skipLogics[0] });
                  }
               } else {
                  if (existingSkipLogic) {
                     item.skipLogics = item.skipLogics.map((logic) =>
                        logic.index === skipLogics[0].index ? { ...logic, ...skipLogics[0] } : logic
                     );
                  } else {
                     item.skipLogics.push(...skipLogics);
                  }
               }
            }
            return item;
         });

         if (!itemExists) {
            const newSkipLogic = {
               sectionId,
               questionId,
               logicalOperator,
               skipLogics
            };

            updatedSkipLogic.push(newSkipLogic);
         }

         return { skipLogicCreate: updatedSkipLogic };
      });
   },
   deleteSkipLogic: (question, index, value) => {
      set((state) => {
         const updatedSkipLogic = state.skipLogicCreate.map((item) => {
            if (item.questionId === question.id || item.sectionId === question.id) {
               if (item.skipLogics.length > 0) {
                  // later: value is not passed in general delete item from skipLogicCreate state
                  if (value) {
                     item.skipLogics = item.skipLogics.filter((logic) => logic.value !== value);
                  } else {
                     item.skipLogics = item.skipLogics.filter((logic) => logic.index !== index);
                  }

                  if (item.skipLogics.length === 0) {
                     return {
                        ...item,
                        skipLogics: []
                     };
                  }
               } else {
                  return undefined;
               }
            }
            return item;
         });

         return { skipLogicCreate: updatedSkipLogic };
      });
   },
   setLogicalOperator: (question, logicalOperator) => {
      set((state) => {
         const sectionId = question?.itemLabel === "Section" && question?.id;
         const questionId = !question?.itemLabel && question?.id;

         const updatedSkipLogic = state.skipLogicCreate.map((item) => {
            if (item.questionId === questionId || item.sectionId === sectionId) {
               item.logicalOperator = logicalOperator;
            }
            return item;
         });

         return { skipLogicCreate: updatedSkipLogic };
      });
   },
   setSkipLogicCreateFromBackend: (skipLogicData) => {
      set((state) => {
         return {
            skipLogicCreate: [
               ...state.skipLogicCreate,
               {
                  sectionId: skipLogicData.sectionId,
                  questionId: skipLogicData.questionId,
                  logicalOperator: skipLogicData.logicalOperator,
                  skipLogics: skipLogicData.skipLogics
               }
            ]
         };
      });
   },
   clearSkipLogicCreate: () => {
      set((state) => {
         return {
            skipLogicCreate: []
         };
      });
   }
}));
