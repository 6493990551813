import React, { useState, useEffect, useCallback } from "react";
import MembersView from "../../../components/MembersView/MembersView";
import { httpService } from "../../../base/services/httpService.service";
import imgBanner from "../../../assets/images/UserManagementBanner.png";
import { withErrorBoundary } from "../../../components/ErrorBoundary/withErrorBoundary";

import styles from "./UserManagement.module.scss";

function UserManagement() {
   const [workspaces, setWorkspaces] = useState([]);
   const [projects, setProjects] = useState([]);

   const columns = [
      {
         title: <span className={styles.styledTitle}>Name</span>,
         dataIndex: "fullName",
         width: 400,
         render: (_, record) => (
            <div className={styles.rowName}>
               {/* <div> */}
               <div className={styles.circleName}>
                  <span className={styles.circleLetter}>
                     {" "}
                     {record.fullName.charAt(0).toUpperCase()}
                  </span>
               </div>
               <div>
                  <span className={styles.styledDataIndex1}>{record.fullName}</span>
                  <br />
                  <span className={styles.styledDataIndex2}>{record.email}</span>
               </div>
            </div>
         )
      },
      // {
      //    title: <span className={styles.styledTitle}>Email</span>,
      //    dataIndex: "email",
      //    render: (text) => <span className={styles.styledDataIndex}>{text}</span>
      // },
      {
         title: <span className={styles.styledTitle}>Gender</span>,
         dataIndex: "gender",
         render: (text) => <span className={styles.styledDataIndex}>{text}</span>
      },
      {
         title: <span className={styles.styledTitle}>Languages</span>,
         dataIndex: "selectedLanguages",
         render: (languages) => (
            <span className={styles.styledDataIndex}>
               {Array.isArray(languages)
                  ? languages?.map((u) => u.name).join(", ")
                  : languages[0]?.name}
            </span>
         )
      },
      {
         title: <span className={styles.styledTitle}>Location</span>,
         dataIndex: "country",
         render: (country) => <span className={styles.styledDataIndex}>{country?.name}</span>
      },
      {
         title: <span className={styles.styledTitle}>Role</span>,
         dataIndex: "roles",
         render: (text) => (
            <span className={styles.styledDataIndex}>
               {text[0]?.name === "OrgSuperAdmin"
                  ? "Organization Super Admin"
                  : text[0]?.name.replace(/([a-z])([A-Z])/g, "$1 $2")}
            </span>
         )
      },
      {
         title: <span className={styles.styledTitle}>Workspace</span>,
         dataIndex: "workspaces",
         render: (workspaces) => (
            <span className={styles.styledDataIndex}>
               {Array.isArray(workspaces)
                  ? workspaces?.map((u) => u.name).join(", ")
                  : workspaces[0]?.name}
            </span>
         )
      }
   ];

   const getProjectsOfOrganization = useCallback(() => {
      httpService.get("/Projects/getProjectsOfOrganization", (res) => {
         if (!res?.data) return;
         setProjects(
            res.data.map((project) => ({
               id: project.id,
               name: project.name,
               value: project.id,
               label: project.name
            }))
         );
      });
   }, []);

   useEffect(() => {
      getProjectsOfOrganization();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      httpService.get("/Workspaces/getMyWorkspaces", (res) => {
         if (!res?.data) return;
         setWorkspaces(
            res.data.map((workspace) => ({
               id: workspace.id,
               name: workspace.name,
               value: workspace.id,
               label: workspace.name
            }))
         );
      });
   }, []);

   return (
      <>
         <div className={styles.userManagementHeader}>
            <div className={styles.organizationsBannerInfo}>
               <h2>User Management</h2>
            </div>
            <div className={styles.organizationsBannerImage}>
               <img src={imgBanner} alt="User management banner" />
            </div>
         </div>
         <MembersView
            workspaces={workspaces}
            columns={columns}
            projects={projects}
            adminUserManagementPage
         />
      </>
   );
}
export default withErrorBoundary(UserManagement);
